import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    side: {
      type: String,
      default: "right"
    }
  }

  static targets = ["fixedTop", "scrollable"]

  initialize() {
    this.fixScrollableHeight = this.fixScrollableHeight.bind(this)
  }

  connect() {
    this.hide() // hide to reset classes
    this.show() // show to make visible
    document.addEventListener("scroll", this.fixScrollableHeight)
  }

  disconnect() {
    document.removeEventListener("scroll", this.fixScrollableHeight)
  }

  get nav() {
    return document.querySelector(`.${this.sideValue}-side-nav`)
  }

  get main() {
    return document.querySelector("main")
  }

  get isOpen() {
    return this.nav.classList.contains("shown")
  }

  get navStyles() {
    return ["g-0", "col-sm-12", "col-md-6", "col-lg-4", "col-xl-3"]
  }

  get mainStyles() {
    return ["g-0", "d-none", "d-md-block", "col-md-6", "col-lg-8", "col-xl-9"]
  }

  show() {
    this.nav.classList.add(...this.navStyles)
    this.nav.classList.add("d-sm-block")
    this.nav.setAttribute("aria-hidden", "false")

    this.nav.classList.remove("hidden")
    this.nav.classList.add("shown")

    this.main.classList.add(...this.mainStyles)
    this.fixScrollableHeight()
  }

  hide() {
    this.nav.setAttribute("aria-hidden", "true")
    this.nav.classList.remove(...this.navStyles)
    this.nav.classList.add("d-none")
    this.nav.classList.add("hidden")
    this.nav.classList.remove("shown")

    this.main.classList.remove(...this.mainStyles)
  }

  fixScrollableHeight() {
    if (!this.fixedTopTarget) return

    const maxHeight = window.innerHeight - this.fixedTopTarget.getBoundingClientRect().bottom
    this.scrollableTarget.style.overflowY = "scroll"
    this.scrollableTarget.style.maxHeight = `${maxHeight}px`
  }

  teardown() {
    this.hide()
  }
}